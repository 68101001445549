import { useState } from 'react';
import { Drawer } from 'antd';
import { isEmpty } from 'lodash';
import { ArrowLeftToLine } from 'lucide-react';
import moment from 'moment';
import TaggedUsers from './TaggedUsers';
import { BaseLoader, NoDataFoundScreen } from '@common/components';
import { COLORS } from '@constants';
export function DashboardComments({
  isCommentMode,
  setIsCommentMode,
  commentsListing,
  formik,
  scrollContainerRef,
  handleScroll,
  tagUsersList,
  userDetail,
  isLoading,
}) {
  const [expandedComments, setExpandedComments] = useState({});

  const { submitForm, setFieldValue, values } = formik;

  const parseList = list => {
    if (!isEmpty(list)) {
      return list?.map(lis => {
        return {
          id: lis.id,
          img: lis.profileImage?.image,
          display: lis.name,
        };
      });
    }

    return [];
  };

  const toggleText = commentId => {
    setExpandedComments(prev => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };

  return (
    <Drawer
      title={null}
      width={'31.25vw'}
      className='widgetDrawer'
      mask={false}
      onClose={() => setIsCommentMode(false)}
      open={isCommentMode}
    >
      <div className='bg-tableHeaderBackground px-8 py-6'>
        <span
          className='mb-6 inline-flex cursor-pointer items-center gap-4 text-lg font-semibold leading-[1.625rem] text-mainText'
          onClick={() => setIsCommentMode(false)}
        >
          <ArrowLeftToLine color={COLORS.ICON_COLOR} className='h-5 w-5' />
          Comments
        </span>
        <div className='flex w-full items-end gap-2'>
          <span className='inline-block h-11 min-w-11 overflow-hidden rounded-full'>
            <img src={userDetail?.data?.profileImage?.image} className='h-full w-full' />
          </span>
          <TaggedUsers
            users={parseList(tagUsersList)}
            setFieldValue={setFieldValue}
            values={values}
            submitComment={submitForm}
          />
        </div>
      </div>
      {isLoading ? (
        <BaseLoader />
      ) : (
        <ul
          className='flex h-[calc(100vh-13.5rem)] flex-col overflow-y-auto overflow-x-hidden'
          ref={scrollContainerRef}
          onScroll={handleScroll}
        >
          {commentsListing?.pages?.map(page =>
            page?.data?.count === 0 ? (
              <div key={page} className='h-full'>
                <NoDataFoundScreen
                  title='No Comments Yet'
                  message='It looks like there aren’t any comments here at the moment. Be the first to share your thoughts or feedback.'
                />
              </div>
            ) : (
              page?.data?.results?.map(comment => {
                const isExpanded = expandedComments[comment?.id] || false;
                const truncatedText = comment?.text.slice(0, 190);
                const fullText = comment?.text;
                const isLongComment = comment?.text?.length > 190;

                return (
                  <li key={comment?.id} className='border-b border-secondaryStrokeButton px-8 py-6'>
                    <div className='flex items-center justify-between gap-2'>
                      <div className='flex items-center gap-4'>
                        <span className='h-11 w-11 overflow-hidden rounded-full'>
                          <img src={comment?.user?.profileImage?.image} alt='person' />
                        </span>
                        <div>
                          <p className='text-sm font-semibold leading-5 text-mainText'>{comment?.user?.name}</p>
                          <p className='text-xs leading-4 text-bodyText'>{comment?.user?.email}</p>
                        </div>
                      </div>
                      <span className='text-[0.625rem] font-normal text-dateColor'>
                        {moment(comment?.created).fromNow()}
                      </span>
                    </div>
                    {!!comment?.widgetLabel && (
                      <span className='mt-3 inline-block text-sm leading-5 text-bodyText'>
                        Widget: <span className='font-normal'>{comment?.widgetLabel}</span>
                      </span>
                    )}
                    <span key={comment?.id} className='flex'>
                      <p
                        className='comment-desc mt-3 break-words break-all text-sm leading-6 text-bodyText'
                        dangerouslySetInnerHTML={{
                          __html: isExpanded
                            ? `${fullText} <span class="toggle-text">see less</span>`
                            : `${truncatedText} ${isLongComment ? '... <span class="toggle-text">see more</span>' : ''}`,
                        }}
                        onClick={isLongComment ? () => toggleText(comment?.id) : undefined}
                      />
                    </span>
                  </li>
                );
              })
            )
          )}
        </ul>
      )}
    </Drawer>
  );
}
