import {
  AlignStartVertical,
  BarChart3,
  DatabaseZap,
  Goal,
  Home,
  LayoutDashboard,
  LineChart,
  PieChart,
  Settings,
} from 'lucide-react';
import { ROUTES } from '@routes';
import clicks from '@assets/images/clicks.png';
import image1 from '@assets/images/Logo1.png';
import image2 from '@assets/images/Logo2.png';
import image3 from '@assets/images/Logo3.png';
import image4 from '@assets/images/Logo4.png';
import image5 from '@assets/images/Logo5.png';
import image6 from '@assets/images/Logo6.png';
import image7 from '@assets/images/Logo7.png';
import image8 from '@assets/images/Logo8.png';
import orders from '@assets/images/orders.png';
import roas from '@assets/images/roas.png';
import sales from '@assets/images/sales.png';

export const ACCESS_TOKEN = 'accessToken';

export const ENABLE = 'enable';

export const SIDEBAR_CONTENT = [
  {
    iconUrl: Home,
    text: 'Home',
    link: 'home',
  },
  {
    iconUrl: LayoutDashboard,
    text: 'Dashboards',
    link: 'dashboards',
  },
  {
    iconUrl: DatabaseZap,
    text: 'Data Sources',
    link: 'data-sources',
  },
  {
    iconUrl: Goal,
    text: 'Goal Tracking',
    link: 'goal-tracking',
  },
  {
    iconUrl: Settings,
    text: 'Manage Organization',
    link: 'manage-organization',
  },
];

export const DATA_STAT = [
  {
    imgUrl: sales,
  },
  {
    imgUrl: clicks,
  },
  {
    imgUrl: roas,
  },
  {
    imgUrl: orders,
  },
];

export const MEMBERSHIP_TIER_GENERAL_FEATURES = [
  { name: 'Organizations' },
  { name: 'Users' },
  { name: 'Dashboards' },
  { name: 'Multi-integration Dashboards' },
  { name: 'Preset Templates' },
  { name: 'Drag and Drop Builder' },
];

export const INTEGRATION_FEATURES = [{ name: 'Native Integrations' }];

export const CUSTOMIZATION_FEATURE = [
  { name: 'Custom Logo' },
  { name: 'Custom Domain' },
  { name: 'Custom Email Domain' },
];

export const LOGIN_STATE = {
  ON_BOARDED: 'ONBOARDED',
  VERIFIED: 'VERIFIED',
};

export const COUNT_DOWN_TIME = 5 * 60;

export const CANCEL_MEMBERSHIP_DATE_FORMATE = 'D MMMM YYYY';

export const COLORS = {
  COLOR_PRIMARY: '#0F172A',
  ERROR_COLOR: '#EF4444',
  TOGGLE_DISABLE_COLOR: '#334155',
  WHITE_COLOR: '#ffffff',
  DARK_PRIMARY: '#3FB79A',
  TINT_GRAY: '#B7B7B7',
  DARK_SECONDARY: '#1C1C23',
  LIME_GREEN: '#8AC33D',
  PINK_THEME: '#e0464b',
  ORANGE_THEME: '#e87a2c',
  BLUE_THEME: '#3b5998',
  DARK_RED_THEME: '#bd081c',
  BLACK_THEME: '#333333',
  NAVY_BLUE_THEME: '#000080',
  DARK_GREEN_THEME: '#006400',
  INDIGO_THEME: '#4B0082',
  STEEL_BLUE_THEME: '#36454F',
  MAROON_THEME: '#800020',
  CRIMSON_THEME: '#D21515',
  DEEP_PINK_THEME: '#D500E7',
  TEAL_THEME: '#008080',
  GOLDEN_THEME: '#E1B238',
  CARROT_ORANGE_THEME: '#E4702E',
  MALACHITE_THEME: '#15D283',
  THEME_COLOR: '#FAFAFA',
  MAP_WIDGET_COLOR: '#9AC8FC',
  MAP_WIDGET_HOVER_COLOR: '#D6D6DA',
  SLATE_GREY: '#64748B',
  LIGHT_GREY: '#E2E8F0',
  ON_TRACK_COLOR: '#B7B13F',
  OFF_TRACK_COLOR: '#B73F3F',
  COMPLETED: '#3FB79A',
  BODY_TEXT_COLOR: '#5D5D5D',
  SECONDARY_GREEN_COLOR: '#386360',
  BUTTON_DISABLED_COLOR: '#C9C9C9',
  PRIMARY_GREEN_COLOR: '#3FB79A',
  LOGO_DARK: '#1C1C23',
  ICON_COLOR: '#596780',
  TAB_COLOR: '#596780',
  TAB_ACTIVE_COLOR: '#3FB79A',
  TEXT_MAIN_COLOR: '#190F0F',
  ERROR_TOOLTIP: '#EF4444',
  BUTTON_DISABLE: '#C9C9C9',
  SECONDARY_STROKE_BTN: '#E2E8F0',
  SHADE_1: '#E2F8F4',
  SHADE_3: '#E7F9F3',
  OFF_TRACK_BG_COLOR: '#FFEFEF',
  ON_TRACK_BG_COLOR: '#F7F6DE',
  HIGHLIGHT_TEXT_COLOR: '#3fb79a',
  HIGHLIGHT_TEXT3_COLOR: '#125750',
};

export const ARROW_KEYS = {
  LEFT: 37,
  RIGHT: 39,
  ESCAPE: 27,
};

export const MANAGE_ORGANIZATION_TABS = { MEMBERS: 'members', TEAMS: 'teams', INVITES: 'invites' };
export const PROFILE_TABS = {
  PROFILE: 'profile',
  MEMBERSHIP: 'membership',
  AI_TOKEN: 'ai-tokens',
  DOMAIN: 'domain',
};
export const REPORTING_TABS = { CREATE_REPORT: 'create-report', SCHEDULED_REPORTS: 'scheduled-reports' };
export const HOME_ORGANIZATION_TABS = {
  MY_ORGANIZATION: 'my-organization',
  INVITED_ORGANIZATION: 'invited-organization',
};
export const PROFILE_TABS_LIST = [
  { path: ROUTES.PROFILE_TAB, value: PROFILE_TABS.PROFILE, label: 'Profile' },
  { path: ROUTES.MEMBERSHIPS_TAB, value: PROFILE_TABS.MEMBERSHIP, label: 'Membership' },
  { path: ROUTES.AI_TOKENS_TAB, value: PROFILE_TABS.AI_TOKEN, label: 'AI Credits' },
  { path: ROUTES.DOMAIN_TAB, value: PROFILE_TABS.DOMAIN, label: 'Domains' },
];

export const goalTrackingProgressValues = goalsProgress => [
  {
    id: 1,
    value: goalsProgress?.data?.totalOnTrack,
    color: COLORS.ON_TRACK_COLOR,
    title: 'On Track',
    description: 'This shows the percentage of goals that are on track across the organization.',
  },
  {
    id: 2,
    value: goalsProgress?.data?.totalOffTrack,
    color: COLORS.OFF_TRACK_COLOR,
    title: 'Off Track',
    description: 'This reflects the percentage of goals that are off track at the organizational level.',
  },
  {
    id: 3,
    value: goalsProgress?.data?.totalCompleted,
    color: COLORS.COMPLETED,
    title: 'Completed',
    description: 'This indicates the percentage of goals successfully completed within the organization.',
  },
];

export const PROFILE_TABS_CONTENT = [
  PROFILE_TABS.PROFILE,
  PROFILE_TABS.MEMBERSHIP,
  PROFILE_TABS.AI_TOKEN,
  PROFILE_TABS.BILLING,
  PROFILE_TABS.DOMAIN,
];

export const STATE = 'STATE';
export const MAIN_LOGO = 'Main Logo';
export const SPONSORED_LOGO = 'Sponsored Logo';
export const MAIN_LOGO_DIMENSION = '110X110';
export const SPONSORED_LOGO_DIMENSION = '220X40';
export const ORGANIZATION_ID_BY_INVITE_ACCEPTANCE = 'temporaryOrgIdForRedirection';
export const ORGANIZATION_ID_BY_ONBOARDING = 'orgIdFromOnBoarding';

export const STATUS = { ACCEPTED: 'ACCEPTED', PENDING: 'PENDING' };

export const COUNT_DOWN_TIME_TEXT = 'countDownTime';
export const CREATED_ORGANIZATION = 'Create Organization';

export const SELECTED_ORGANIZATION_ID = 'selectedOrganization';
export const DEFAULT_ORGANIZATION_ID = 'defaultOrganizationId';

export const NETWORK_ERROR = 'Network Error';
export const ERROR_STATUS_500 = 5;
export const ERROR_STATUS_401 = 401;
export const ERROR_STATUS_404 = 404;

export const EMAIL = 'email';

export const SKELETON_TYPE = {
  IMAGE_UPLOADER: 'IMAGE_UPLOADER',
  SINGLE_LINE: 'SINGLE_LINE',
  SUBSCRIPTION_PLAN: 'SUBSCRIPTION_PLAN',
};

export const ENTER = 'Enter';
export const ENTER_KEY = 13;
export const BACKSPACE = 'Backspace';
export const DELETE = 'Delete';
export const ESCAPE = 'Escape';

export const ROLE_DROP_DOWN_OPTIONS = [
  { id: 'ADMIN', name: 'Admin' },
  { id: 'MEMBER', name: 'Member' },
];

export const PERMISSION_DROP_DOWN_OPTIONS = [
  { id: 'CAN_VIEW', name: 'Can View' },
  { id: 'CAN_EDIT', name: 'Can Edit' },
];

export const PERMISSION_TOGGLES = {
  CAN_VIEW: 'CAN_VIEW',
  CAN_EDIT: 'CAN_EDIT',
};

export const INVITED_ORGANIZATION = 'invitedOrganization';
export const TOTAL_ITEMS_PER_PAGE = 12;

export const DATE_FORMAT = {
  NOTIFICATION_DATE: 'YYYY-MM-DD HH:mm:ss',
  BILLING_DATE: 'DD MMM YYYY',
  RANGE_DATE: 'YYYY-MM-DD',
  DATE_PICKER: 'MMM DD, yyyy',
  CALENDAR_DATE_PICKER: 'MMM dd, yyyy',
  STANDARD_TIME_FORMAT: 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)',
  REPORT_FORMAT: 'DD-MM-YY',
  REPORT_LISTING_FORMAT: 'DD-MM-YYYY',
  CUSTOM_DATE_FORMAT: 'MMM D, YYYY',
};

export const TIME_UNITS = {
  MINUTES: 'minutes',
  SECONDS: 'seconds',
  MONTH: 'month',
  YEAR: 'year',
};

export const FIRST_PAGE = 1;

export const ROLES = {
  ADMIN: 'ADMIN',
  MEMBER: 'MEMBER',
};

export const IS_OTP_EXPIRE = 'isOtpExpire';

export const CURRENCY = {
  USD: 'usd',
};

export const DASHBOARD_VIEW_TYPE = {
  TABLE: 'table',
  GRID: 'grid',
  NORMAL: 'NORMAL',
  SLIDES: 'SLIDES',
};

export const SUBSCRIPTION_PLAN = {
  INDIVIDUAL_PLAN: 'INDIVIDUAL_PLAN',
  BUSINESS_PLAN: 'BUSINESS_PLAN',
  AGENCY_PLAN: 'AGENCY_PLAN',
};

export const DATA_SOURCES_TEXT = 'data-sources';
export const GOAL_TRACKING_TEXT = 'goal-tracking';

export const CURRENCY_DROP_DOWN_OPTIONS = [
  { id: 'USD', name: 'US Dollar' },
  // { id: 'EUR', name: 'Euro' },
];

export const CREATE_DASHBOARD_TYPE = {
  CUSTOM: 'custom',
  TEMPLATE: 'template',
};

export const WIDGETS_TYPE = {
  SPEED_O_METER: 'SPEEDO_METER',
  LINE_CHART: 'LINE_CHART',
  BAR_CHART: 'BAR_CHART',
  PIE_CHART: 'PIE_CHART',
  DONUT_CHART: 'DONUT_CHART',
  COUNTER: 'COUNTER',
  PROGRESS_BAR: 'PROGRESS_BAR',
  TABLE: 'TABLE',
  TEXT: 'TEXT',
  VIDEO: 'VIDEO',
  MAP: 'MAP',
  IMAGE: 'IMAGE',
  CSV: 'CSV',
  GOAL: 'GOAL',
};

export const WIDGETS_TABS = {
  CUSTOM_WIDGETS: 'custom widgets',
  STATIC_WIDGETS: 'static widgets',
};

export const GOAL_TRACKING_TAB = {
  TABULAR_VIEW: 'tabular view',
  GRID_VIEW: 'grid-view',
};

export const EDIT_WIDGET_TABS = {
  GENERAL: 'General',
  DATA: 'Data',
  DISPLAY: 'Display',
};

export const TEMPLATE_TAB = {
  SYSTEM_TEMPLATE: 'System Templates',
  MY_TEMPLATE: 'My Templates',
};

export const STATIC = 'static';
export const VIDEO_TEXTS = 'Video';
export const IMAGE_TEXTS = 'Image';
export const CSV_TEXTS = 'Csv';

export const REFETCH_INTERVAL_TIME = 10000;

export const POLL_STATUS_TYPE = {
  PENDING: 'pending',
  RUNNING: 'running',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
};

export const DATA_SOURCES = {
  SHOPIFY: 'shopify',
  WOO_COMMERCE: 'woocommerce',
  HUBSPOT: 'hubspot',
  BING_ADS: 'bingads',
  YOUTUBE_ANALYTICS: 'youtube-analytics',
  MAIL_CHIMP: 'mailchimp',
  GOOGLE_ANALYTICS: 'google-analytics',
  FACEBOOK_MARKETING: 'facebook-marketing',
  ZOHO_CRM: 'zohocrm',
  ACTIVE_CAMPAIGN: 'active-campaign',
};

export const UPPERCASE_DATA_SOURCES = {
  SHOPIFY: 'SHOPIFY',
  WOO_COMMERCE: 'WOO_COMMERCE',
  HUBSPOT: 'HUBSPOT',
  BING_ADS: 'BING_ADS',
  YOUTUBE_ANALYTICS: 'YOUTUBE_ANALYTICS',
  MAIL_CHIMP: 'MAILCHIMP',
  GOOGLE_ANALYTICS: 'GOOGLE_ANALYTICS',
  FACEBOOK_MARKETING: 'FACEBOOK_MARKETING',
  STRIPE: 'STRIPE',
  KLAVIYO: 'KLAVIYO',
  PIPE_DRIVE: 'PIPE_DRIVE',
  LINKEDIN_ADS: 'LINKEDIN_ADS',
  TYPEFORM: 'TYPEFORM',
  ZOHO_CRM: 'ZOHO_CRM',
  ACTIVE_CAMPAIGN: 'ACTIVE_CAMPAIGN',
  CLICK_UP: 'CLICKUP',
  INSTAGRAM: 'INSTAGRAM',
  TIKTOK_MARKETING: 'TIKTOK_MARKETING',
  GOOGLE_ADS: 'GOOGLE_ADS',
};

export const DATE_RANGE_DROP_DOWN = [
  { name: 'Today', id: 'TODAY' },
  { name: 'Yesterday', id: 'YESTERDAY' },
  { name: 'Last 7 Days', id: 'LAST_WEEK' },
  { name: 'Last 30 Days', id: 'LAST_MONTH' },
  { name: 'Custom', id: 'CUSTOM' },
];

export const DATE_RANGE_OPTIONS = {
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  LAST_7_DAY: 'LAST_WEEK',
  LAST_30_DAYS: 'LAST_MONTH',
  CUSTOM: 'CUSTOM',
};

export const MESSAGE = {
  RECEIVER: 'RECEIVER',
};

export const CHART_CONVERSION = [
  {
    Icon: LineChart,
    label: 'Line',
    value: WIDGETS_TYPE.LINE_CHART,
  },
  {
    Icon: AlignStartVertical,
    label: 'Bar',
    value: WIDGETS_TYPE.PROGRESS_BAR,
  },
  {
    Icon: BarChart3,
    label: 'Bar',
    value: WIDGETS_TYPE.BAR_CHART,
  },
  {
    Icon: PieChart,
    label: 'Pie',
    value: WIDGETS_TYPE.PIE_CHART,
  },
  // {
  //   Icon: Globe,
  //   label: 'Map',
  //   value: 'MAP',
  // },
];

export const DEFAULT_DEBOUNCE_INTERVAL = 500;
export const DEFAULT_VALUE = 'DEFAULT_VALUE';

export const THEME_COLORS_OPTIONS = [
  {
    foreground: COLORS.LIME_GREEN,
    background: COLORS.WHITE_COLOR,
    id: 'LIME_GREEN',
  },
  {
    foreground: COLORS.DARK_PRIMARY,
    background: COLORS.WHITE_COLOR,
    id: 'DARK_PRIMARY',
  },
  {
    foreground: COLORS.PINK_THEME,
    background: COLORS.WHITE_COLOR,
    id: 'PINK_THEME',
  },
  {
    foreground: COLORS.BLACK_THEME,
    background: COLORS.WHITE_COLOR,
    id: 'BLACK_THEME',
  },
  {
    foreground: COLORS.ORANGE_THEME,
    background: COLORS.WHITE_COLOR,
    id: 'ORANGE_THEME',
  },
  {
    foreground: COLORS.BLUE_THEME,
    background: COLORS.WHITE_COLOR,
    id: 'BLUE_THEME',
  },
  {
    foreground: COLORS.DARK_RED_THEME,
    background: COLORS.WHITE_COLOR,
    id: 'DARK_RED_THEME',
  },
  {
    foreground: COLORS.NAVY_BLUE_THEME,
    background: COLORS.WHITE_COLOR,
    id: 'NAVY_BLUE_THEME',
  },
  {
    foreground: COLORS.DARK_GREEN_THEME,
    background: COLORS.WHITE_COLOR,
    id: 'DARK_GREEN_THEME',
  },
  {
    foreground: COLORS.INDIGO_THEME,
    background: COLORS.WHITE_COLOR,
    id: 'INDIGO_THEME',
  },
  {
    foreground: COLORS.STEEL_BLUE_THEME,
    background: COLORS.WHITE_COLOR,
    id: 'STEEL_BLUE_THEME',
  },
  {
    foreground: COLORS.MAROON_THEME,
    background: COLORS.WHITE_COLOR,
    id: 'MAROON_THEME',
  },
  {
    foreground: COLORS.CRIMSON_THEME,
    background: COLORS.WHITE_COLOR,
    id: 'CRIMSON_THEME',
  },
  {
    foreground: COLORS.DEEP_PINK_THEME,
    background: COLORS.WHITE_COLOR,
    id: 'DEEP_PINK_THEME',
  },
  {
    foreground: COLORS.TEAL_THEME,
    background: COLORS.WHITE_COLOR,
    id: 'TEAL_THEME',
  },
  {
    foreground: COLORS.GOLDEN_THEME,
    background: COLORS.WHITE_COLOR,
    id: 'GOLDEN_THEME',
  },
  {
    foreground: COLORS.CARROT_ORANGE_THEME,
    background: COLORS.WHITE_COLOR,
    id: 'CARROT_ORANGE_THEME',
  },
  {
    foreground: COLORS.MALACHITE_THEME,
    background: COLORS.WHITE_COLOR,
    id: 'MALACHITE_THEME',
  },
];

export const INSTRUCTION_DATA = [
  { id: 1, instruction: 'The three plans above are purchase options for buying AI credits.' },
  {
    id: 2,
    instruction: 'If you are short on AI credits from your monthly or yearly subscription, you can buy with no expiry.',
  },
  { id: 3, instruction: 'Unused credits will carry over to the next cycle.' },
  { id: 4, instruction: 'You can make multiple purchases from the same or different plans.' },
];

export const THEME_STYLE = {
  LIGHT: 'light',
  DARK: 'dark',
};

export const INVALID_DATE = 'Invalid date';

export const OPERATIONS_TYPE = [
  { name: 'Count', id: 'COUNT' },
  { name: 'Sum', id: 'SUM' },
];

export const SORT_BY_OPTION = [
  { name: 'Asc', id: 'ASC' },
  { name: 'Desc', id: 'DESC' },
];

export const DISPLAY_TAB_OPTIONS = [
  { name: 'Yes', id: true },
  { name: 'No', id: false },
];

export const THEME_STYLE_OPTIONS = [
  { name: 'Light', id: 'light' },
  { name: 'Dark', id: 'dark' },
];

export const LIMIT_DROP_DOWN = [
  { name: '10', id: 10 },
  { name: '20', id: 20 },
  { name: '30', id: 30 },
  { name: '40', id: 40 },
  { name: '50', id: 50 },
  { name: '100', id: 100 },
];

export const DAY_VALUE = [
  { value: '0', label: 'S' },
  { value: '1', label: 'M' },
  { value: '2', label: 'T' },
  { value: '3', label: 'W' },
  { value: '4', label: 'T' },
  { value: '5', label: 'F' },
  { value: '6', label: 'S' },
];

export const REPORT_TIME_PERIOD = [
  { name: 'Weekly', id: 'WEEKLY' },
  { name: 'Monthly', id: 'MONTHLY' },
];

export const NON_AUTH_CONNECTOR_INPUT_FIELDS = {
  [UPPERCASE_DATA_SOURCES.SHOPIFY]: [
    {
      name: 'shop',
      label: 'Shop',
      infoContent: 'shopContent',
    },
    {
      name: 'accessToken',
      label: 'Access Token',
      infoContent: 'accessTokenContent',
    },
  ],
  [UPPERCASE_DATA_SOURCES.PIPE_DRIVE]: [
    {
      name: 'apiToken',
      label: 'Api Token',
      infoContent: 'apiTokenContent',
    },
  ],
  [UPPERCASE_DATA_SOURCES.KLAVIYO]: [
    {
      name: 'apiKey',
      label: 'Api Key',
      infoContent: 'apiTokenContent',
    },
  ],
  [UPPERCASE_DATA_SOURCES.STRIPE]: [
    {
      name: 'accountId',
      label: 'Account Id',
      infoContent: 'accountIdContent',
    },
    {
      name: 'secretKey',
      label: 'Secret Key',
      infoContent: 'secretKeyContent',
    },
  ],
  [UPPERCASE_DATA_SOURCES.ZOHO_CRM]: [
    {
      name: 'edition',
      label: 'Edition',
      dropDownOptions: [
        { id: 'Free', name: 'Free' },
        { id: 'Standard', name: 'Standard' },
        { id: 'Professional', name: 'Professional' },
        { id: 'Enterprise', name: 'Enterprise' },
        { id: 'Ultimate', name: 'Ultimate' },
      ],
      infoContent: 'editionContent',
    },
    {
      name: 'environment',
      label: 'Environment',
      dropDownOptions: [
        { id: 'Production', name: 'Production' },
        { id: 'Developer', name: 'Developer' },
        { id: 'Sandbox', name: 'Sandbox' },
      ],
      infoContent: 'environmentContent',
    },
  ],
  [UPPERCASE_DATA_SOURCES.ACTIVE_CAMPAIGN]: [
    {
      name: 'accountUsername',
      label: 'Account Username',
      infoContent: 'accountUsernameContent',
    },
    {
      name: 'apiKey',
      label: 'Api Key',
      infoContent: 'apiKeyContent',
    },
  ],
};

export const NON_AUTH_CONNECTORS = [
  UPPERCASE_DATA_SOURCES.SHOPIFY,
  UPPERCASE_DATA_SOURCES.PIPE_DRIVE,
  UPPERCASE_DATA_SOURCES.KLAVIYO,
  UPPERCASE_DATA_SOURCES.STRIPE,
  UPPERCASE_DATA_SOURCES.ACTIVE_CAMPAIGN,
];

export const ONE_TIME = 'ONE_TIME';
export const MONTHLY = 'MONTHLY';
export const WEEKLY = 'WEEKLY';

export const INDIVIDUAL_PLAN = 'Individual Plan';

export const SHOPIFY_SHOP_TEXT = (
  <p>
    Your shop name is located in the URL of your Shopify account,
    <br /> after the <i>https://</i>. You need to add
    <b> yourshop.myshopify.com</b> as your shopname
  </p>
);

export const SHOPIFY_ACCESS_TOKEN_TEXT = (
  <ul>
    <li>Log in to your Shopify account.</li>
    <li>
      In the dashboard, navigate to <b>Settings &gt; App and sales channels &gt; Develop apps &gt; Create an app</b>.
    </li>
    <li>Select a name for your new app.</li>
    <li>Select Configure Admin API scopes.</li>
    <li>
      Grant access to the following list of scopes. Only select scopes prefixed with read_, not write_ (e.g.
      read_locations,read_price_rules, etc ).
    </li>
    <li>Click Install app to give this app access to your data.</li>
    <li>Once installed, go to API Credentials to copy the Admin API Access Token.</li>
  </ul>
);

export const ACTIVE_CAMPAIGN_ACCOUNT_USERNAME_TEXT = (
  <p>
    You can find your account username from{' '}
    <a
      target='_blank'
      href='https://www.activecampaign.com/'
      className='text-darkPrimary underline hover:opacity-[.7]'
      rel='noreferrer'
    >
      active campaign
    </a>{' '}
    <b>Settings &gt; Developer &gt; URL</b>. <br /> e.g: https://&lt;account_username&gt;.activehosted.com
  </p>
);

export const ACTIVE_CAMPAIGN_API_KEY_TEXT = (
  <p>
    You can find your accounts API key from{' '}
    <a
      target='_blank'
      href='https://www.activecampaign.com/'
      className='text-darkPrimary underline hover:opacity-[.7]'
      rel='noreferrer'
    >
      active campaign
    </a>{' '}
    <b>Settings &gt; Developer &gt; Key</b>. <br /> e.g: XXXXXXXXX
  </p>
);

export const ZOHO_CRM_EDITION_TEXT = (
  <p>
    Log in to your{' '}
    <a
      target='_blank'
      href='https://crm.zoho.com/crm/'
      className='text-darkPrimary underline hover:opacity-[.7]'
      rel='noreferrer'
    >
      Zoho CRM
    </a>{' '}
    account and click the top right corner of <br /> the profile picture to view the plan you are currently using.
  </p>
);

export const CONNECTOR_NAME_SCREEN = (
  <p>Enter a name that will be used as an identifier for the connected data source.</p>
);

export const WOO_COMMERCE_TEXT = (
  <p>
    The name of the woocommerce store. e.g https://EXAMPLE.com, the store name is <b>&apos;EXAMPLE.com&apos;</b>;
  </p>
);

export const BING_ADS_TEXT = <p>Enter the label for your bing ads connection.</p>;

export const TIME_PERIOD = [
  { id: 1, name: 'Day' },
  { id: 7, name: 'Week' },
  { id: 30, name: 'Month' },
  { id: 90, name: 'Quarter' },
  { id: 365, name: 'Year' },
];

export const GOAL_CONDITION = [
  { id: 'GREATER_THAN', name: 'Greater than' },
  { id: 'GREATER_THAN_OR_EQUAL', name: 'Greater than or equal to' },
  { id: 'LESS_THAN', name: 'Less than' },
  { id: 'LESS_THAN_OR_EQUAL', name: 'Less than or equal to' },
];

export const INVALID_PAGE = 'Invalid page.';

export const SYSTEM_GENERATED_IMAGES = [
  {
    id: 0,
    img: image1,
  },
  {
    id: 1,
    img: image2,
  },
  {
    id: 2,
    img: image3,
  },
  {
    id: 3,
    img: image4,
  },
  {
    id: 4,
    img: image5,
  },
  {
    id: 5,
    img: image6,
  },
  {
    id: 6,
    img: image7,
  },
  {
    id: 7,
    img: image8,
  },
];
