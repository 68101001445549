import { useState } from 'react';
import { Modal, Radio } from 'antd';
import { Plus, X } from 'lucide-react';
import { useCreateDashboardTour } from '../hooks/useCreateDashboardTour';
import { Button, FormInput, ImageCrop } from '@common/components';
import { delay, getFile, handleSave } from '@utils';
import {
  CREATE_DASHBOARD_NAME_ID,
  COLORS,
  CREATE_DASHBOARD_LOGO_ID,
  CREATE_DASHBOARD_LOGO_TYPE_ID,
  CREATE_DASHBOARD_LOGO_UPLOAD,
  SYSTEM_LOGO_ID,
  CREATE_DASHBOARD_STEP1_BUTTON,
  SYSTEM_GENERATED_IMAGES,
} from '@constants';
import { ImagePlaceholderIcon, PickSystemGenerated, UploadFromDevice } from '@assets/svgs';

export function CreateDashboardStep1({
  formik,
  imageRef,
  uploadedImage,
  isUploadedImage,
  setIsUploadedImage,
  handleImageUpload,
  isTourActive,
  imageLoading,
}) {
  const { initialValues, values, setFieldValue, touched, errors } = formik;
  const [systemGeneratedImage, setSystemGeneratedImage] = useState(null);
  const [showCropModal, setShowCropModal] = useState(false);

  const [imageType, setImageType] = useState('DEVICE');
  const { createDashboardTour } = useCreateDashboardTour();

  const handleStaticImageUpload = async () => {
    if (systemGeneratedImage) {
      // Fetch the image as a blob
      const response = await fetch(systemGeneratedImage.img);
      const blob = await response.blob();

      const file = new File([blob], 'uploaded-image.png', { type: blob.type });

      handleSave(file, handleImageUpload);
      setIsUploadedImage(false);
      setImageType('DEVICE');
    }
  };

  return (
    <div id='step-1' className=''>
      <div className='flex flex-col gap-2'>
        <h2 className='font-Manrope text-xl font-bold leading-7 text-mainText'>Dashboard Details</h2>
        <p className='font-Manrope text-sm leading-5 text-bodyText'>Enter dashboard name and further details</p>
      </div>
      <div className='flex flex-col gap-8 pt-10'>
        <div className='flex flex-col gap-4'>
          <span className='font-Manrope text-sm leading-5 text-labelText'>Logo</span>
          <div className='flex w-[8.375rem] flex-col items-center gap-3'>
            <div
              className={`relative flex h-[7.5rem] w-[7.5rem] items-center justify-center rounded-full border ${touched.image && errors.image ? 'border-errorColor' : ''}`}
            >
              <>
                {uploadedImage?.image || values?.image ? (
                  <div className='h-full w-full overflow-hidden rounded-full'>
                    <img src={uploadedImage?.image || values?.image} alt='' className='h-full w-full object-cover' />
                  </div>
                ) : (
                  <div onClick={() => setIsUploadedImage(true)} className='h-full w-full'>
                    <ImagePlaceholderIcon className='h-full w-full cursor-pointer' />
                  </div>
                )}
                <div
                  className='absolute bottom-0 right-0 flex h-[2rem] w-[2rem] cursor-pointer items-center justify-center rounded-full bg-highlighterText1'
                  onClick={() => {
                    setIsUploadedImage(true);
                  }}
                >
                  <div
                    id={CREATE_DASHBOARD_LOGO_ID}
                    className='flex h-6 w-6 items-center justify-center rounded-full bg-highlighterText1'
                  >
                    <Plus className='h-3.5 w-3.5' color='white' />{' '}
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
        <div className='flex flex-col gap-8'>
          <FormInput
            labelText='Name'
            labelClassName='font-Manrope'
            placeholder='Enter dashboard name'
            className='w-[28.75rem] gap-1.5'
            id={CREATE_DASHBOARD_NAME_ID}
            value={values.name}
            handleChange={e => {
              setFieldValue('name', e.target.value);
            }}
            showError={touched.name && errors.name}
            inputClassName='h-10'
          />
          {/* <FormSelect
            id={CREATE_DASHBOARD_CURRENCY_ID}
            tourId={CREATE_DASHBOARD_CURRENCY_DROPDOWN}
            labelText='Currency'
            placeholder='US Dollar'
            value={values.currency}
            handleChange={selectedCurrency => setFieldValue('currency', selectedCurrency)}
            options={CURRENCY_DROP_DOWN_OPTIONS}
            showError={touched.currency && errors.currency}
          /> */}
        </div>
      </div>
      <Modal
        footer={null}
        width={showCropModal && imageType !== 'SYSTEM' ? 400 : 540}
        closeIcon={<X color={COLORS.ICON_COLOR} />}
        open={isUploadedImage}
        afterOpenChange={status => !status && setShowCropModal(false)}
        onCancel={() => {
          setIsUploadedImage(false);
          setFieldValue('image', '');
          setImageType('DEVICE');
          setShowCropModal(false);
        }}
        centered={true}
      >
        <div className='flex flex-col gap-6'>
          <div className='font-Manrope text-2xl font-bold leading-[2.125rem] text-mainText'>
            {showCropModal && imageType !== 'SYSTEM'
              ? 'Crop Image'
              : imageType === 'SYSTEM'
                ? 'System Generated'
                : 'Add Logo'}
          </div>
          <div className={showCropModal && 'min-h-[300px]'}>
            {showCropModal && imageType !== 'SYSTEM' ? (
              <ImageCrop
                uploadedImage={values?.image}
                handleCancel={() => {
                  setIsUploadedImage(false);
                  setFieldValue('image', initialValues.image);
                  imageRef.current.value = initialValues.image;
                }}
                imageRef={imageRef}
                setIsUploaded={setIsUploadedImage}
                handleSave={file => handleSave(file, handleImageUpload)}
                imageLoading={imageLoading}
                onBackButtonClick={() => {
                  setShowCropModal(false);
                  setImageType('DEVICE');
                  setFieldValue('image', '');
                }}
              />
            ) : imageType === 'SYSTEM' ? (
              <div className='flex flex-wrap items-center gap-5' id={SYSTEM_LOGO_ID}>
                {SYSTEM_GENERATED_IMAGES?.map(image => (
                  <div
                    key={image?.id}
                    className={`relative flex h-[6.25rem] w-[6.25rem] cursor-pointer items-center justify-center overflow-hidden rounded-full ${systemGeneratedImage?.id === image?.id && 'outline outline-2 outline-offset-8 outline-highlighterText1'}`}
                    onClick={() => setSystemGeneratedImage(image)}
                  >
                    <img src={image?.img} alt='logo' className='h-full w-full object-cover' />
                  </div>
                ))}
              </div>
            ) : (
              <Radio.Group
                className='!me-0 flex gap-5'
                defaultValue='NORMAL'
                value={imageType}
                onChange={async e => {
                  setImageType(e?.target?.value);
                  if (isTourActive) {
                    if (e?.target?.value === 'DEVICE') createDashboardTour().moveNext();
                    else {
                      await delay(300);
                      createDashboardTour().moveTo(5);
                    }
                  }
                }}
                id={CREATE_DASHBOARD_LOGO_TYPE_ID}
              >
                <Radio
                  value='DEVICE'
                  id='DEVICE'
                  className='relative !me-0 flex h-[11.625rem] w-[13.75rem] justify-center !rounded-xl border border-secondaryStrokeButton px-8 py-8 after:content-[unset]'
                >
                  <div className='flex w-[140px] flex-col items-center gap-0.5'>
                    <UploadFromDevice className='h-[6.25rem] min-w-[6.25rem]' />
                    <h6 className='text-nowrap text-center font-Manrope text-sm font-semibold leading-5 text-mainText'>
                      Upload from Device
                    </h6>
                  </div>
                </Radio>
                <Radio
                  value='SYSTEM'
                  id='SYSTEM'
                  className='relative !me-0 flex h-[11.625rem] w-[13.75rem] justify-center !rounded-xl border border-secondaryStrokeButton px-8 py-8'
                >
                  <div className='flex w-[140px] flex-col items-center gap-0.5'>
                    <PickSystemGenerated className='h-[6.25rem] min-w-[6.25rem]' />
                    <h6 className='text-nowrap text-center font-Manrope text-sm font-semibold leading-5 text-mainText'>
                      Pick System Generated
                    </h6>
                  </div>
                </Radio>
              </Radio.Group>
            )}
          </div>
          {!showCropModal && imageType === 'DEVICE' && (
            <Button className='relative w-full' size='lg' id={CREATE_DASHBOARD_LOGO_UPLOAD}>
              <input
                type='file'
                ref={imageRef}
                accept='.jpg, .jpeg, .png'
                onChange={e => {
                  getFile(e.target.files[0], 'image', setFieldValue, setIsUploadedImage);
                  setShowCropModal(true);
                }}
                className='absolute z-10 h-full w-full cursor-pointer opacity-0'
              />
              Upload
            </Button>
          )}

          {imageType === 'SYSTEM' && (
            <div className='flex flex-col gap-4'>
              <Button className='w-full' size='lg' id={CREATE_DASHBOARD_STEP1_BUTTON} onClick={handleStaticImageUpload}>
                Add
              </Button>
              <Button
                className='w-full'
                variant='tertiary'
                size='lg'
                onClick={() => {
                  setImageType('DEVICE');
                  setSystemGeneratedImage(null);
                  setFieldValue('image', '');
                }}
              >
                Go Back
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}
