import { useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { ROUTES } from '@routes';
import { apiEndpoints, useGetQuery, usePostMutation } from '@services';
import {
  capitalizeSourceName,
  getLocalStorageItem,
  parseLinkedinListing,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '@utils';
import {
  ACTIVE_CAMPAIGN_ACCOUNT_USERNAME_TEXT,
  ACTIVE_CAMPAIGN_API_KEY_TEXT,
  INVALID_PAGE,
  NON_AUTH_CONNECTORS,
  SHOPIFY_ACCESS_TOKEN_TEXT,
  SHOPIFY_SHOP_TEXT,
  SUCCESSFUL_MESSAGE,
  TOTAL_ITEMS_PER_PAGE,
  UPPERCASE_DATA_SOURCES,
  ZOHO_CRM_EDITION_TEXT,
} from '@constants';

const nonAuthConnectors = {
  SHOPIFY: {
    shop: '',
    accessToken: '',
    shopContent: {
      text: SHOPIFY_SHOP_TEXT,
    },
    accessTokenContent: {
      text: SHOPIFY_ACCESS_TOKEN_TEXT,
    },
  },
  PIPE_DRIVE: {
    apiToken: '',
    apiTokenContent: {
      text: 'Obtain your pipe drive api key from this ',
      link: 'https://pipedrive.readme.io/docs/how-to-find-the-api-token',
    },
  },
  KLAVIYO: {
    apiKey: '',
    apiTokenContent: {
      text: 'Find your klaviyo API key from this ',
      link: 'https://help.klaviyo.com/hc/en-us/articles/115005062267#your-private-api-keys3',
    },
  },
  STRIPE: {
    accountId: '',
    secretKey: '',
    accountIdContent: {
      text: 'Your Stripe account id (starts with "acct_"), find yours from this',
      link: 'https://dashboard.stripe.com/settings/account',
    },
    secretKeyContent: {
      text: 'Stripe API key (usually starts with "sk_live_"); find yours',
      link: 'https://dashboard.stripe.com/apikeys',
    },
  },
  ZOHO_CRM: {
    edition: '',
    environment: '',
    editionContent: {
      text: ZOHO_CRM_EDITION_TEXT,
    },
    environmentContent: {
      text: 'Please choose the environment your account is using, default is Production.',
    },
  },
  ACTIVE_CAMPAIGN: {
    apiKey: '',
    accountUsername: '',
    accountUsernameContent: {
      text: ACTIVE_CAMPAIGN_ACCOUNT_USERNAME_TEXT,
    },
    apiKeyContent: {
      text: ACTIVE_CAMPAIGN_API_KEY_TEXT,
    },
  },
};

export function useAddDataSource() {
  const { organizationId, page: pageNumber } = useParams();
  const navigate = useNavigate();

  const showConnector = getLocalStorageItem('showConnector');
  const savePageNumber = getLocalStorageItem('dataSourceCurrentPage');

  const [search, setSearch] = useState('');
  const [showConnectDataSourceDialog, setShowConnectDataSourceDialog] = useState(showConnector || {});
  const [connectedDataSourceLoading, setConnectDataSourceLoading] = useState(false);
  const [checkedOauthId, setCheckedOauthId] = useState();
  const [checkedAccountId, setCheckedAccountId] = useState();
  const [showNameField, setShowNameField] = useState(false);
  const [isNonAuthConnector, setIsNonAuthConnector] = useState(false);
  const [showAccount, setShowAccount] = useState(false);
  const [checkedAccount, setCheckedAccount] = useState(null);
  const [linkedInAccounts, setLinkedInAccounts] = useState([]);
  const [checkedIds, setCheckedIds] = useState([]);
  const [showProperties, setShowProperties] = useState();
  const [checkedPropertyId, setCheckedPropertyId] = useState();
  const [checkedProperty, setCheckedProperty] = useState();
  const [nameField, setNameField] = useState('');
  const [nonAuthFields, setNonAuthFields] = useState(nonAuthConnectors);
  const [showStep2, setShowStep2] = useState(false);
  const [elementId, setElementId] = useState('');
  const [showSelectField, setShowSelectField] = useState(false);
  const [oAuthListing, setOAuthListing] = useState({});
  const [showConnectorNameScreen, setShowConnectorNameScreen] = useState(false);
  const [nextConnectBtnEnable, setNextConnectBtnEnable] = useState(false);

  const page = +pageNumber || savePageNumber || 1;
  const [paginationPage, setPaginationPage] = useState(page);
  const [totalPagesPerItem, setTotalPagesPerItem] = useState(TOTAL_ITEMS_PER_PAGE);

  const {
    data: accountListing,
    refetch: accountListingRefetch,
    isFetching: accountListingLoading,
  } = useGetQuery(
    'account-listing',
    apiEndpoints.GOOGLE_ANALYTICS_ACCOUNT(checkedOauthId),
    {},
    { retry: false, refetchOnWindowFocus: false, enabled: false, onSuccess: () => setShowAccount(true) }
  );

  const {
    data: linkedinAccountListing,
    refetch: linkedinAccountListingRefetch,
    isFetching: linkedinAccountListingLoading,
  } = useGetQuery(
    'linkedinAccount-listing',
    apiEndpoints.LINKEDIN_ADS(checkedOauthId),
    {},
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: () => {
        setShowAccount(true);
        setShowStep2(true);
      },
    }
  );

  const {
    data: tikTokAccountListing,
    refetch: tikTokAccountListingRefetch,
    isFetching: tikTokAccountLoading,
  } = useGetQuery(
    'tikTok-listing',
    apiEndpoints.TIK_TOK_ADVERTISERS(checkedOauthId),
    {},
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: () => {
        setShowAccount(true);
        setShowStep2(true);
      },
    }
  );

  const {
    data: googleAdsCustomerListing,
    refetch: googleAdsCustomerListingRefetch,
    isFetching: googleAdsCustomerLoading,
  } = useGetQuery(
    'google-ads-listing',
    apiEndpoints.GOOGLE_ADS_CUSTOMERS(checkedOauthId),
    {},
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: () => {
        setShowAccount(true);
        setShowStep2(true);
      },
    }
  );

  const {
    data: accountPropertiesListing,
    refetch: accountPropertiesListingRefetch,
    isFetching: accountPropertiesLoading,
  } = useGetQuery(
    'account-properties-listing',
    apiEndpoints.GOOGLE_ANALYTICS_ACCOUNT_PROPERTIES(checkedOauthId, checkedAccount?.name?.split('/')[1]),
    {},
    { retry: false, refetchOnWindowFocus: false, enabled: false, onSuccess: () => setShowProperties(true) }
  );

  const {
    data: fbAccountListing,
    refetch: fbAccountListingRefetch,
    isFetching: fbAccountListingLoading,
  } = useGetQuery(
    'fb-account-listing',
    apiEndpoints.FACEBOOK_ACCOUNT(checkedOauthId),
    {},
    { retry: false, refetchOnWindowFocus: false, enabled: false, onSuccess: () => setShowAccount(true) }
  );

  const {
    data: addDataSourceListing,
    refetch: addDataSourceListingRefetch,
    isLoading,
    isFetching: connectDataSourceLoading,
  } = useGetQuery(
    'addDataSource-listing',
    apiEndpoints.ADD_DATA_SOURCE,
    { page, pageSize: totalPagesPerItem, search },
    {
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
      onError: ({ response: { data } }) => {
        if (data?.detail === INVALID_PAGE) {
          handlePage(1);
          setPaginationPage(1);
        }
        toast.error(data?.detail);
      },
    }
  );

  const { isFetching: oAuthListingLoading } = useGetQuery(
    ['oauth-listing'],
    apiEndpoints.DATA_SOURCE_OAUTH_LISTING,
    { sourceName: capitalizeSourceName(showConnectDataSourceDialog?.sourceName) },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled:
        // !NON_AUTH_CONNECTORS.includes(capitalizeSourceName(showConnectDataSourceDialog?.sourceName)) &&
        !isEmpty(showConnectDataSourceDialog),
      onSuccess: resp => setOAuthListing(resp),
    }
  );

  const {
    data: instagramPagesListing,
    refetch: instagramPagesRefetch,
    isFetching: instagramPagesLoading,
  } = useGetQuery(
    'instagram-pages-listing',
    apiEndpoints.INSTAGRAM_DATA_SOURCES_PAGES(checkedOauthId),
    {},
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: () => setShowAccount(true),
    }
  );

  const {
    data: youtubeAccountListing,
    refetch: youtubeAccountListingRefetch,
    isFetching: youtubeAccountListingLoading,
  } = useGetQuery(
    'youtube-listing',
    apiEndpoints.YOUTUBE_DATA_SOURCES(checkedOauthId),
    {},
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: () => setShowAccount(true),
      onError: ({ response: { data } }) => toast.error(data?.detail || data?.detail[0]),
    }
  );

  const handlePage = pageNum => {
    navigate(generatePath(ROUTES.ADD_DATA_SOURCE, { organizationId, page: pageNum }));
  };

  const handleNameChange = e => setNameField(e.target.value);

  const clearDialogue = () => {
    setShowConnectDataSourceDialog({});
    setShowSelectField(false);
    setShowNameField(false);
    setIsNonAuthConnector(false);
    setShowAccount(false);
    setShowProperties(false);
    setCheckedOauthId(null);
    setCheckedAccountId(null);
    setLinkedInAccounts([]);
    setCheckedIds([]);
    setCheckedProperty(null);
    setShowStep2(false);
    setNonAuthFields(nonAuthConnectors);
    setOAuthListing({});
    setNameField('');
    setShowConnectorNameScreen(false);
    setNextConnectBtnEnable(false);
    setCheckedAccount(null);
  };

  const handleBackClick = () => {
    if (showProperties) {
      setShowProperties(false);
    } else if (showConnectorNameScreen) {
      setShowConnectorNameScreen(false);
      setNextConnectBtnEnable(false);
      setElementId(null);
      setCheckedAccount(null);
      setCheckedAccountId(null);
    } else {
      setShowSelectField(false);
      setShowNameField(false);
      setIsNonAuthConnector(false);
      setLinkedInAccounts([]);
      setCheckedIds([]);
      setShowAccount(false);
      setShowStep2(false);
      setShowConnectorNameScreen(false);
      setNextConnectBtnEnable(false);
      setCheckedAccount(null);
      setElementId('');
    }
  };

  const handleNonAuthFields = (connector, fieldName, value) => {
    setNonAuthFields(prev => {
      return {
        ...prev,
        [connector]: { ...nonAuthFields[connector], [fieldName]: value },
      };
    });
  };

  const handleConnectDataSource = () => {
    const { sourceName, id } = showConnectDataSourceDialog || {};
    const commonPayload = {
      connectorName: capitalizeSourceName(sourceName),
      connectorId: id,
      oauth: checkedOauthId,
      name: nameField,
    };

    let payload = {};

    switch (capitalizeSourceName(sourceName)) {
    case UPPERCASE_DATA_SOURCES.GOOGLE_ANALYTICS:
      if (showAccount) {
        if (showProperties) {
          payload = { ...commonPayload, propertyId: checkedProperty?.name?.split('/')[1] };
        }
      }
      break;

    case UPPERCASE_DATA_SOURCES.FACEBOOK_MARKETING:
      if (showAccount) {
        payload = { ...commonPayload, accountId: checkedAccount?.id };
      }
      break;

    case UPPERCASE_DATA_SOURCES.LINKEDIN_ADS:
      if (showAccount) {
        payload = {
          ...commonPayload,
          accountIds: [checkedAccount?.name],
        };
      }
      break;

    case UPPERCASE_DATA_SOURCES.TIKTOK_MARKETING:
      if (showAccount) {
        payload = {
          ...commonPayload,
          advertiser: checkedAccount?.id,
        };
      }
      break;

    case UPPERCASE_DATA_SOURCES.GOOGLE_ADS:
      if (showAccount) {
        payload = {
          ...commonPayload,
          customer: checkedAccount?.id,
        };
      }
      break;

    case UPPERCASE_DATA_SOURCES.INSTAGRAM:
      if (showAccount) {
        payload = {
          ...commonPayload,
          accessToken: checkedAccount?.accessToken,
          id: checkedAccount?.id,
        };
      }
      break;

    case UPPERCASE_DATA_SOURCES.YOUTUBE_ANALYTICS:
      if (showAccount) {
        payload = {
          ...commonPayload,
          channelId: checkedAccount?.id,
        };
      }
      break;

    default:
      if (NON_AUTH_CONNECTORS.includes(capitalizeSourceName(sourceName))) {
        let nonAuthPayload = { ...nonAuthFields[capitalizeSourceName(sourceName)], ...commonPayload };

        delete nonAuthPayload['oauth'];
        delete nonAuthPayload['accessTokenContent'];
        delete nonAuthPayload['shopContent'];

        payload = nonAuthPayload;
      } else {
        payload = commonPayload;
      }
      break;
    }

    if (!isEmpty(payload)) {
      setConnectDataSourceLoading(true);
      handleOauthConnection({ payload });
    }
  };

  const handleConnectNewAccount = () => {
    let payload = {};

    if (capitalizeSourceName(showConnectDataSourceDialog?.sourceName) === UPPERCASE_DATA_SOURCES.WOO_COMMERCE) {
      showNameField
        ? (payload = {
          connectorName: capitalizeSourceName(showConnectDataSourceDialog?.sourceName),
          storeName: nameField,
        })
        : (setShowNameField(true), setNextConnectBtnEnable(true), setShowAccount(true));
    } else if (capitalizeSourceName(showConnectDataSourceDialog?.sourceName) === UPPERCASE_DATA_SOURCES.BING_ADS) {
      showNameField
        ? ((payload = {
          connectorName: capitalizeSourceName(showConnectDataSourceDialog?.sourceName),
          accountName: nameField,
        }),
        setLocalStorageItem('accountName', nameField))
        : (setShowNameField(true), setNextConnectBtnEnable(true), setShowAccount(true));
    } else if (NON_AUTH_CONNECTORS.includes(capitalizeSourceName(showConnectDataSourceDialog?.sourceName))) {
      setIsNonAuthConnector(true);
    } else {
      payload = {
        connectorName: capitalizeSourceName(showConnectDataSourceDialog?.sourceName),
      };
    }

    !isEmpty(payload) && connectNewAccount({ payload });
  };

  const handleConnectorNameValidation = () => {
    const { sourceName, id } = showConnectDataSourceDialog || {};
    const capitalizedSourceName = capitalizeSourceName(sourceName);

    const singleStepDataSource =
      capitalizedSourceName === UPPERCASE_DATA_SOURCES.WOO_COMMERCE ||
      capitalizedSourceName === UPPERCASE_DATA_SOURCES.BING_ADS ||
      capitalizedSourceName === UPPERCASE_DATA_SOURCES.HUBSPOT ||
      capitalizedSourceName === UPPERCASE_DATA_SOURCES.MAIL_CHIMP;

    const twoStepsDataSources =
      capitalizedSourceName === UPPERCASE_DATA_SOURCES.INSTAGRAM ||
      capitalizedSourceName === UPPERCASE_DATA_SOURCES.GOOGLE_ADS ||
      capitalizedSourceName === UPPERCASE_DATA_SOURCES.FACEBOOK_MARKETING ||
      capitalizedSourceName === UPPERCASE_DATA_SOURCES.LINKEDIN_ADS ||
      capitalizedSourceName === UPPERCASE_DATA_SOURCES.TIKTOK_MARKETING ||
      capitalizedSourceName === UPPERCASE_DATA_SOURCES.YOUTUBE_ANALYTICS;

    const twoStepsDataSourcesActions = {
      [UPPERCASE_DATA_SOURCES.INSTAGRAM]: instagramPagesRefetch,
      [UPPERCASE_DATA_SOURCES.GOOGLE_ADS]: googleAdsCustomerListingRefetch,
      [UPPERCASE_DATA_SOURCES.FACEBOOK_MARKETING]: fbAccountListingRefetch,
      [UPPERCASE_DATA_SOURCES.LINKEDIN_ADS]: linkedinAccountListingRefetch,
      [UPPERCASE_DATA_SOURCES.YOUTUBE_ANALYTICS]: youtubeAccountListingRefetch,
      [UPPERCASE_DATA_SOURCES.TIKTOK_MARKETING]: tikTokAccountListingRefetch,
    };

    const threeStepsDataSources = capitalizedSourceName === UPPERCASE_DATA_SOURCES.GOOGLE_ANALYTICS;

    const threeStepsDataSourcesActions = {
      [UPPERCASE_DATA_SOURCES.GOOGLE_ANALYTICS]: accountListingRefetch,
    };

    const nonAuthDataSource = capitalizedSourceName === UPPERCASE_DATA_SOURCES.SHOPIFY;

    if (singleStepDataSource) {
      if (checkedOauthId) {
        setShowConnectorNameScreen(true);
        setNextConnectBtnEnable(true);
        setShowAccount(true);

        if (showConnectorNameScreen) {
          handleValidateName({
            payload: {
              name: nameField,
              connector: id,
              organization: organizationId,
            },
          });
        }
      } else {
        setShowConnectorNameScreen(false);
      }
    }

    if (twoStepsDataSources) {
      if (checkedOauthId) {
        isEmpty(checkedAccount) && twoStepsDataSourcesActions[capitalizedSourceName]?.();
        if (!isEmpty(checkedAccount)) {
          setShowConnectorNameScreen(true);
          setNextConnectBtnEnable(true);
          if (showConnectorNameScreen) {
            handleValidateName({
              payload: {
                name: nameField,
                connector: id,
                organization: organizationId,
              },
            });
          }
        } else {
          setShowConnectorNameScreen(false);
        }
      }
    }

    if (threeStepsDataSources) {
      if (checkedOauthId) {
        isEmpty(checkedAccount) && !showProperties && threeStepsDataSourcesActions[capitalizedSourceName]?.();

        if (!isEmpty(checkedAccount)) {
          !showProperties && accountPropertiesListingRefetch();
          if (checkedPropertyId) {
            setShowConnectorNameScreen(true);
            setNextConnectBtnEnable(true);
            if (showConnectorNameScreen) {
              handleValidateName({
                payload: {
                  name: nameField,
                  connector: id,
                  organization: organizationId,
                },
              });
            }
          }
        } else {
          setShowConnectorNameScreen(false);
        }
      }
    }

    if (nonAuthDataSource) {
      setShowConnectorNameScreen(true);
      setNextConnectBtnEnable(true);
      setShowAccount(true);

      if (showConnectorNameScreen) {
        handleValidateName({
          payload: {
            name: nameField,
            connector: id,
            organization: organizationId,
          },
        });
      }
    }
  };

  const {
    mutate: connectNewAccount,
    //  isLoading: connectNewAccountLoading
  } = usePostMutation(
    apiEndpoints.DATA_SOURCES_OAUTH,
    resp => {
      window.location.href = resp?.data?.url;
      setConnectDataSourceLoading(false);
      setLocalStorageItem('organizationId', organizationId);
      setLocalStorageItem('showConnector', showConnectDataSourceDialog);
      setLocalStorageItem('dataSourceCurrentPage', page);
    },
    ({ response: { data } }) => {
      setConnectDataSourceLoading(false);
      toast.error(data?.detail);
    }
  );

  const { mutate: handleOauthConnection } = usePostMutation(
    apiEndpoints.CONNECT_DATA_SOURCE(organizationId),
    () => {
      setShowConnectDataSourceDialog({});
      toast.success(SUCCESSFUL_MESSAGE('Data source connected'));
      navigate(generatePath(ROUTES.DATA_SOURCE, { organizationId }));
    },
    ({ response: { data } }) => {
      toast.error(data?.detail);
      setConnectDataSourceLoading(false);
    }
  );

  const { mutate: handleValidateName, isLoading: validateNameLoading } = usePostMutation(
    apiEndpoints.DATASOURCE_VALIDATE_NAME,
    () => handleConnectDataSource(),
    ({ response: { data } }) => toast.error(data?.detail[0])
  );

  useEffect(() => {
    (page || totalPagesPerItem) && addDataSourceListingRefetch();
    removeLocalStorageItem('organizationId');
    removeLocalStorageItem('showConnector');

    return () => {
      removeLocalStorageItem('dataSourceCurrentPage');
    };
  }, [page, search, totalPagesPerItem]);

  useEffect(() => {
    if (!isEmpty(linkedinAccountListing)) {
      const accounts = parseLinkedinListing(linkedinAccountListing);
      setLinkedInAccounts(accounts);
    }
  }, [linkedinAccountListing]);

  return {
    addDataSourceListing,
    page,
    setPage: handlePage,
    paginationPage,
    setPaginationPage,
    totalPagesPerItem,
    setTotalPagesPerItem,
    setSearch,
    showConnectDataSourceDialog,
    setShowConnectDataSourceDialog,
    addDataSourceLoading: connectDataSourceLoading || isLoading,
    connectedDataSourceLoading,
    organizationId,
    handleConnectNewAccount,
    oAuthListing,
    setOAuthListing,
    oAuthListingLoading,
    checkedOauthId,
    setCheckedOauthId,
    setConnectDataSourceLoading,
    showNameField,
    clearDialogue,
    handleBackClick,
    showAccount,
    accountListing,
    checkedAccountId,
    setCheckedAccountId,
    setCheckedAccount,
    showProperties,
    accountPropertiesListing,
    checkedPropertyId,
    setCheckedPropertyId,
    setCheckedProperty,
    fbAccountListing,
    nameField,
    handleNameChange,
    handleNonAuthFields,
    nonAuthFields,
    isNonAuthConnector,
    linkedInAccounts,
    showStep2,
    checkedIds,
    showSelectField,
    instagramPagesListing,
    tikTokAccountListing,
    elementId,
    setElementId,
    googleAdsCustomerListing,
    youtubeAccountListing,
    showConnectorNameScreen,
    validateNameLoading,
    nextConnectBtnEnable,
    // connectNewAccountLoading,
    handleConnectorNameValidation,
    instagramPagesLoading,
    googleAdsCustomerLoading,
    fbAccountListingLoading,
    linkedinAccountListingLoading,
    youtubeAccountListingLoading,
    tikTokAccountLoading,
    accountListingLoading,
    accountPropertiesLoading,
  };
}
