import { CustomCheckbox } from '@common/components';

export function OAuthUrlListing({ listing, checkedId, setCheckedId }) {
  return (
    <div className='flex h-[12rem] flex-col overflow-y-auto'>
      {listing?.data?.results?.map(oauth => (
        <div
          key={oauth?.id}
          onClick={() => setCheckedId(prev => (prev === oauth?.id ? null : oauth.id))}
          className='flex cursor-pointer items-center gap-2 border-b border-secondaryStrokeButton px-4 py-3.5 text-sm leading-5'
        >
          {oauth?.label && (
            <CustomCheckbox
              id={`checkbox-${oauth?.id}`}
              checked={checkedId === oauth?.id}
              // onChange={() => setCheckedId(prev => (prev === oauth?.id ? null : oauth.id))}
            />
          )}
          <label className='cursor-pointer'>{oauth?.label}</label>
        </div>
      ))}
    </div>
  );
}
