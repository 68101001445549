import { useState, useRef, useEffect, useId } from 'react';
import { Select } from 'antd';
import { ConnectorGuide } from '@common/components';
import { capitalizeString } from '@utils';

const DROPDOWN_EVENT = 'FORM_SELECT_DROPDOWN_CHANGE';

export function FormSelect({
  id,
  labelText,
  placeholder,
  value,
  handleChange,
  showError,
  optionPlaceholder,
  options = [],
  labelClassName,
  className,
  triggerClassName,
  tourId,
  selectRef,
  handleScroll,
  selectGroupClassName,
  showInfoIcon = false,
  infoContent,
  capitalizeOptions = true,
  ...restProps
}) {
  const [isOpen, setIsOpen] = useState(false);
  const selectRefInternal = useRef(null);

  const uniqueId = useId();
  const generatedId = id || uniqueId;

  useEffect(() => {
    const handleDropdownChange = event => {
      if (event.detail.id !== generatedId && isOpen) {
        setIsOpen(false);
      }
    };

    const handleClickOutside = event => {
      if (selectRefInternal.current && !selectRefInternal.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener(DROPDOWN_EVENT, handleDropdownChange);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener(DROPDOWN_EVENT, handleDropdownChange);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [generatedId, isOpen]);

  const handleSelectChange = selectedValue => {
    handleChange(selectedValue);
    setIsOpen(false);
  };

  const handleDropdownToggle = () => {
    const newIsOpen = !isOpen;
    setIsOpen(newIsOpen);

    if (newIsOpen) {
      window.dispatchEvent(new CustomEvent(DROPDOWN_EVENT, { detail: { id: generatedId } }));
    }
  };

  return (
    <div id={generatedId} className={`relative flex flex-col gap-1.5 ${className}`} ref={selectRefInternal}>
      {!!labelText && (
        <label htmlFor={generatedId} className={`relative w-fit text-sm leading-5 text-labelText ${labelClassName}`}>
          {labelText}
          <ConnectorGuide showInfoIcon={showInfoIcon} infoContent={infoContent} />
        </label>
      )}
      <Select
        onMouseDown={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        value={value || null}
        open={isOpen}
        onClick={handleDropdownToggle}
        onChange={handleSelectChange}
        popupClassName='form-select-options p-2 rounded-xl border border-secondaryStrokeButton'
        className={`form-select h-[unset] ${showError ? 'form-select-error' : ''} ${triggerClassName}`}
        placeholder={placeholder}
        dropdownRender={menu => (
          <div className='no-scrollbar' onScroll={handleScroll} ref={selectRef}>
            <div
              className={options?.length === 0 ? '' : `no-scrollbar ${selectGroupClassName}`}
              id={tourId}
              onClick={e => e.stopPropagation()}
            >
              {options?.length === 0 ? (
                <div
                  className='rounded-md bg-disableInput p-2 text-left text-sm leading-5 text-icon1'
                  id={tourId}
                  onClick={() => setIsOpen(false)}
                >
                  {optionPlaceholder || placeholder}
                </div>
              ) : (
                menu
              )}
            </div>
          </div>
        )}
        onPopupScroll={handleScroll}
        {...restProps}
      >
        {options?.map(option => (
          <Select.Option key={option?.id} value={option?.id}>
            <div className='!bg-[unset] p-3 text-left text-sm leading-5 text-bodyText hover:bg-shade4'>
              {capitalizeOptions
                ? capitalizeString(option?.name?.replace('_', ' ')) ||
                  capitalizeString(option?.email?.replace('_', ' '))
                : option?.name.toLowerCase()}
            </div>
          </Select.Option>
        ))}
      </Select>
      {!!showError && (
        <div className="absolute -bottom-9 z-[2] rounded bg-errorTooltip px-2 py-[0.375rem] text-xs font-medium leading-4 text-white before:absolute before:left-3 before:top-[-6px] before:h-3 before:w-3 before:rotate-[-90deg] before:bg-[url('data:image/svg+xml,%3Csvg%20width=%2716%27%20height=%2716%27%20viewBox=%270%200%2016%2016%27%20fill=%27none%27%20xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cg%20id=%27caret--right%27%3E%3Cpath%20id=%27Vector%27%20fill-rule=%27evenodd%27%20clip-rule=%27evenodd%27%20d=%27M6%204L11%208L6%2012V4Z%27%20fill=%27%23EF4444%27%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E')] before:bg-no-repeat">
          {showError}
        </div>
      )}
    </div>
  );
}
