import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { BulbIcon } from '@assets/svgs/BulbIcon';
import { goalTrackingProgressValues } from '@constants/constants';
import { useCreateGoalTrackingTour } from '@pages/goalTracking/hooks/useCreateGoalTrackingTour';
import { Alert, Progress } from 'antd';
import { CircleX, LayoutGrid, List, Plus, Search } from 'lucide-react';
import { BaseLoader, Button, CustomPagination, NoDataFoundScreen, SearchInput, TourButton } from '@common/components';
import {
  CreateEditGoalDrawer,
  GoalTrackGridView,
  GoalTrackTabularView,
  NoGoalsView,
  useEditGoal,
  useGoalsProgress,
} from '@pages/goalTracking';
import { useManageOrganization } from '@pages/manageOrganization';
import { ROUTES } from '@routes';
import { getLocalStorageItem } from '@utils';
import { COLORS, CREATE_GOAL_BUTTON_ID, GOAL_TRACKING_TAB, ROLES } from '@constants';

export function GoalTrack() {
  const [searchParams, setSearchParams] = useSearchParams();

  const initialTab =
    searchParams.get('view') === GOAL_TRACKING_TAB.TABULAR_VIEW
      ? GOAL_TRACKING_TAB.TABULAR_VIEW
      : GOAL_TRACKING_TAB.GRID_VIEW;

  const [currentTab, setCurrentTab] = useState(initialTab);

  const userOrganizationRole = getLocalStorageItem('userOrganizationRole');

  const { createGoalTrackingTour } = useCreateGoalTrackingTour();

  const {
    goalsProgress,
    goalsListing,
    goalDetail,
    setGoalDetail,
    handleGoalDelete,
    showDeleteDialog,
    setShowDeleteDialog,
    deleteGoalLoading,
    setDeleteGoalLoading,
    formik,
    dashboardsListing,
    widgetListing,
    createGoalLoading,
    showCreateDialog,
    setShowCreateDialog,
    setDashboardGoal,
    page,
    setPage,
    setSearch,
    paginationPage,
    setPaginationPage,
    totalPagesPerItem,
    setTotalPagesPerItem,
    goalListingFetching,
  } = useGoalsProgress({
    showToast: true,
  });

  const {
    formik: editFormik,
    showEditDialog,
    setShowEditDialog,
    setEditGoalDetail,
    goalEditLoading,
    setSelectedDashboardId,
    goalDetailLoading,
  } = useEditGoal();

  useEffect(() => {
    setSearchParams({ view: currentTab });
  }, [currentTab, setSearchParams]);

  const { manageOrganization, manageOrganizationFetching } = useManageOrganization();

  const isFirstVisit = !manageOrganization?.data?.haveGoals;
  const noSearchResults = goalsListing?.data?.count === 0;

  return (
    <div className='p-8'>
      <>
        {userOrganizationRole !== ROLES.MEMBER && (
          <CreateEditGoalDrawer
            title='Create Goal'
            formik={formik}
            dashboardsListing={dashboardsListing}
            widgetListing={widgetListing}
            isLoading={createGoalLoading}
            setSelectedDashboardId={setSelectedDashboardId}
            showDrawer={showCreateDialog}
            setShowDrawer={setShowCreateDialog}
            showBaseLoader={goalDetailLoading}
          />
        )}

        <Alert
          message='The goals overall progress will be updated after every 24 hours'
          type='info'
          icon={
            <div className='!mr-3 flex h-8 w-8 items-center justify-center rounded-full bg-white p-2'>
              <BulbIcon />
            </div>
          }
          showIcon
          closable={false}
          closeIcon={<CircleX className='h-5 w-5' color={COLORS.ICON_COLOR} />}
          className='goal-alert mb-6'
          style={{
            backgroundColor: COLORS.SHADE_3,
            border: `1px solid ${COLORS.HIGHLIGHT_TEXT_COLOR}`,
            borderRadius: '12px',
            padding: '12px 24px',
            display: 'flex',
            alignItems: 'center',
            fontSize: '16px',
          }}
        />

        <div className='flex gap-5'>
          {goalTrackingProgressValues(goalsProgress).map(progress => (
            <div
              key={progress.id}
              className='flex h-[12.5rem] items-center gap-8 rounded-xl border bg-white px-12 py-[1.875rem]'
            >
              <div className='flex flex-col gap-2'>
                <h1 className='text-[1.125rem] font-semibold leading-[1.625rem]'>{progress.title}</h1>
                <p className='text-[0.875rem] font-normal leading-[1.5rem]'>{progress.description}</p>
              </div>
              <div className='flex items-center justify-center'>
                <Progress
                  percent={progress.value}
                  type='circle'
                  strokeColor={progress.color}
                  trailColor='#E2E8F0'
                  strokeWidth={10}
                  className='text-[2rem] font-bold'
                  size={140}
                />
              </div>
            </div>
          ))}
        </div>

        {manageOrganizationFetching ? (
          <BaseLoader />
        ) : (
          <div className='mt-8 rounded-xl border bg-white'>
            <div className='flex h-[4.75rem] items-center justify-between border-b px-6 py-4'>
              <h1 className='text-[1.125rem] font-semibold leading-[1.625rem] text-mainText'>All Goals</h1>
              {!isFirstVisit && (
                <div className='flex items-center gap-4'>
                  <SearchInput
                    onChange={e => setSearch(e.target.value)}
                    placeholder='Search'
                    parentClassName='w-[16.25rem]'
                    className='pl-11'
                    leadIcon={<Search className='h-5 w-5' color={COLORS.ICON_COLOR} />}
                    disabled={isFirstVisit}
                  />
                  <div className='ant-tabs-nav flex gap-4'>
                    <div className='ant-tabs-nav-list flex items-center'>
                      <Link
                        to={ROUTES.GOAL_TRACKING_GRID_VIEW}
                        onClick={() => setCurrentTab(GOAL_TRACKING_TAB.GRID_VIEW)}
                        className={`ant-tabs-tab cursor-pointer !px-4 ${currentTab === GOAL_TRACKING_TAB.GRID_VIEW ? 'ant-tabs-tab-active-left' : ''}`}
                      >
                        <div className='ant-tabs-tab-btn'>
                          <LayoutGrid
                            className='h-5 w-5'
                            color={
                              currentTab === GOAL_TRACKING_TAB.GRID_VIEW ? COLORS.TAB_ACTIVE_COLOR : COLORS.TAB_COLOR
                            }
                          />
                        </div>
                      </Link>
                      <Link
                        to={ROUTES.GOAL_TRACKING_TABULAR_VIEW}
                        onClick={() => setCurrentTab(GOAL_TRACKING_TAB.TABULAR_VIEW)}
                        className={`ant-tabs-tab cursor-pointer !px-4 ${currentTab === GOAL_TRACKING_TAB.TABULAR_VIEW ? 'ant-tabs-tab-active-right' : ''}`}
                      >
                        <div className='ant-tabs-tab-btn'>
                          <List
                            className='h-5 w-5'
                            color={
                              currentTab === GOAL_TRACKING_TAB.TABULAR_VIEW ? COLORS.TAB_ACTIVE_COLOR : COLORS.TAB_COLOR
                            }
                          />
                        </div>
                      </Link>
                    </div>
                  </div>
                  {!isFirstVisit && (
                    <Button
                      size='lg'
                      id={CREATE_GOAL_BUTTON_ID}
                      className='w-[10.625rem]'
                      onClick={() => setShowCreateDialog(true)}
                    >
                      <Plus className='h-5 w-5' color='white' />
                      Create New
                    </Button>
                  )}
                </div>
              )}
            </div>

            {isFirstVisit ? (
              <NoGoalsView handleClick={() => setShowCreateDialog(true)} />
            ) : noSearchResults || !goalsListing ? (
              <div className='h-[calc(100vh-448px)] min-h-[372px] overflow-auto'>
                <NoDataFoundScreen />
              </div>
            ) : (
              <>
                {currentTab === GOAL_TRACKING_TAB.TABULAR_VIEW && (
                  <GoalTrackTabularView
                    goalsListing={goalsListing}
                    goalDetail={goalDetail}
                    setGoalDetail={setGoalDetail}
                    setEditGoalDetail={setEditGoalDetail}
                    handleGoalDelete={handleGoalDelete}
                    showDeleteDialog={showDeleteDialog}
                    setShowDeleteDialog={setShowDeleteDialog}
                    deleteGoalLoading={deleteGoalLoading}
                    setDeleteGoalLoading={setDeleteGoalLoading}
                    showEditDialog={showEditDialog}
                    setShowEditDialog={setShowEditDialog}
                    widgetListing={widgetListing}
                    formik={editFormik}
                    dashboardsListing={dashboardsListing}
                    isLoading={goalEditLoading}
                    setSelectedDashboardId={setSelectedDashboardId}
                    setDashboardGoal={setDashboardGoal}
                    goalListingFetching={goalListingFetching}
                  />
                )}
                {currentTab === GOAL_TRACKING_TAB.GRID_VIEW && (
                  <GoalTrackGridView
                    goalsListing={goalsListing}
                    goalDetail={goalDetail}
                    setGoalDetail={setGoalDetail}
                    setEditGoalDetail={setEditGoalDetail}
                    handleGoalDelete={handleGoalDelete}
                    showDeleteDialog={showDeleteDialog}
                    setShowDeleteDialog={setShowDeleteDialog}
                    deleteGoalLoading={deleteGoalLoading}
                    setDeleteGoalLoading={setDeleteGoalLoading}
                    showEditDialog={showEditDialog}
                    setShowEditDialog={setShowEditDialog}
                    widgetListing={widgetListing}
                    formik={editFormik}
                    dashboardsListing={dashboardsListing}
                    isLoading={goalEditLoading}
                    setSelectedDashboardId={setSelectedDashboardId}
                    setDashboardGoal={setDashboardGoal}
                    goalListingFetching={goalListingFetching}
                  />
                )}
                <CustomPagination
                  page={page}
                  setPage={setPage}
                  data={goalsListing}
                  totalItems={totalPagesPerItem}
                  setTotalItems={setTotalPagesPerItem}
                  paginationPage={paginationPage}
                  setPaginationPage={setPaginationPage}
                />
              </>
            )}
            <TourButton Label='“Create Goal”' onClick={() => createGoalTrackingTour().drive()} />
          </div>
        )}
      </>
    </div>
  );
}
