import { useState } from 'react';
import { Modal, Radio } from 'antd';
import { Check, X } from 'lucide-react';
import moment from 'moment';
import { useMembershipTab } from '../hooks/useMembershipTab';
import { Button, BaseLoader, Loader } from '@common/components';
import {
  INTEGRATION_FEATURES,
  CUSTOMIZATION_FEATURE,
  CURRENCY,
  TIME_UNITS,
  COLORS,
  CANCEL_MEMBERSHIP_DATE_FORMATE,
} from '@constants';

export function MembershipTab() {
  const {
    memberShipPlanValue,
    handleRadioToggle,
    membershipPlan,
    userDetail,
    handleMembershipCheckout,
    handleMembershipUpgrade,
    handleCancelMembership,
    showCancelSubscriptionDialog,
    setShowCancelSubscriptionDialog,
    isCancelMembershipLoading,
    membershipCheckoutLoading,
    membershipUpgradeLoading,
    calculateTrialDays,
    membershipFetching,
  } = useMembershipTab();

  const [clickedPlanId, setClickedPlanId] = useState(null);

  const renderCheckOrX = condition =>
    condition === 'Yes' ? (
      <Check className='h-5 w-5' color={COLORS.SECONDARY_GREEN_COLOR} />
    ) : (
      <X className='h-5 w-5 stroke-bodyText' />
    );

  return (
    <div className='relative h-[calc(100vh-14.25rem)] min-h-[23.25rem] overflow-y-scroll rounded-b-xl bg-white shadow-[0_1px_2px_0_rgba(0,0,0,0.05)]'>
      <div className='sticky top-0 z-10 flex flex-row justify-between border-b border-secondaryStrokeButton bg-white p-6'>
        <div className='flex flex-col gap-1'>
          <p className='text-2xl font-bold text-mainText '>Plan & Pricing</p>
          <p className='text-sm text-bodyText'>Unlock Your Potential: Choose the Perfect Plan for You!</p>
        </div>
        <div>
          <Radio.Group
            className='membership-radio-button border-spacing-1 rounded-full border border-secondaryStrokeButton p-0.5 !text-white'
            defaultValue={TIME_UNITS.YEAR}
            buttonStyle='solid'
            value={memberShipPlanValue}
            onChange={handleRadioToggle}
          >
            <Radio.Button
              value={TIME_UNITS.YEAR}
              className={`!rounded-[100px] !border-none ${memberShipPlanValue === TIME_UNITS.YEAR ? '!bg-highlighterText1' : ''} before:bg-transparent before:content-[unset]`}
            >
              Annually
            </Radio.Button>
            <Radio.Button
              value={TIME_UNITS.MONTH}
              className={`!rounded-[100px] !border-none ${memberShipPlanValue === TIME_UNITS.MONTH ? '!bg-highlighterText1' : ''} !border-none before:bg-transparent before:!content-[unset]`}
            >
              Monthly
            </Radio.Button>
          </Radio.Group>
        </div>
      </div>
      <div className='relative px-3 py-6'>
        {membershipFetching ? (
          <div className='h-[calc(100vh-382px)]'>
            <BaseLoader />
          </div>
        ) : (
          <div className='w-full'>
            <div className='max-w-[calc(100vw-5rem)] md:max-w-full'>
              <div className='flex'>
                <div className='mt-3.5 w-[13.9375rem] rounded-l-lg px-4 lg:mt-2.5 lg:w-[9.75rem] xxl:mt-3.5 xxl:w-[13.9375rem]'>
                  <div className='mt-6 h-[10.125rem] border-b border-secondaryStrokeButton px-4 lg:h-[6.75rem] xxl:h-[10.25rem]'>
                    <h5 className='mb-3 text-sm font-semibold leading-[1.21rem] text-highlighterText2 lg:text-xs lg:leading-[0.9075rem] xxl:text-base xxl:leading-[1.21rem]'>
                      Features
                    </h5>
                    <p className='max-w-[10.8125rem] text-xs font-light leading-5 lg:max-w-[7.6875rem] lg:text-[0.5625rem] lg:leading-[0.8888rem] xxl:max-w-[10.8125rem] xxl:text-xs xxl:leading-5'>
                      Review the features provided in each pricing plan to determine the best fit for your needs.
                    </p>
                  </div>
                  <ul className='space-y-5 px-4 pt-6 text-sm font-normal leading-[0.9075rem] text-mainText'>
                    <li>Organizations</li>
                    <li>Users</li>
                    <li>Dashboards</li>
                    <li className='whitespace-pre'>Multi-integration Dashboards</li>
                    <li>Preset Templates</li>
                    <li>Drag and Drop Builder</li>
                    <li>AI Credits</li>
                  </ul>
                  <div className='flex items-center border-b border-paleBlueGray px-4 pb-2 pt-6 text-sm font-bold text-mainText'>
                    Integrations
                  </div>
                  <ul className='space-y-4 p-4 text-xs font-normal leading-[0.9075rem] lg:space-y-3 lg:p-3 lg:text-[0.5625rem] lg:leading-[0.6806rem] xxl:space-y-4 xxl:p-4 xxl:text-xs xxl:leading-[0.9075rem]'>
                    {INTEGRATION_FEATURES.map((data, index) => (
                      <li key={index}>{data.name}</li>
                    ))}
                  </ul>
                  <div className='flex items-center border-b border-paleBlueGray px-4 pb-2 pt-4 text-sm font-bold text-mainText'>
                    White Label & Customization
                  </div>
                  <ul className='space-y-4 p-4 text-xs font-normal leading-[0.9075rem] lg:space-y-3 lg:p-3 lg:text-[0.5625rem] lg:leading-[0.6806rem] xxl:space-y-4 xxl:p-4 xxl:text-xs xxl:leading-[0.9075rem]'>
                    {CUSTOMIZATION_FEATURE.map((data, index) => (
                      <li key={index}>{data.name}</li>
                    ))}
                  </ul>
                </div>
                <div className='flex flex-grow '>
                  {membershipPlan?.data?.results.map(membership => (
                    <div
                      key={membership?.id}
                      className={` ${membership?.isActiveMembership ? '!rounded-md border !border-highlighterText2 !bg-shade1' : 'border-l border-paleBlueGray'} mt-3.5 w-full min-w-[13.5rem] px-4 pt-6 lg:mt-2.5 lg:min-w-[9.375rem] xxl:mt-3.5 xxl:min-w-[13.5rem] `}
                    >
                      <div
                        className={
                          'relative flex h-[10.25rem] w-full flex-col items-center justify-between border-b border-b-paleBlueGray pb-4 lg:h-[8.75rem] xxl:h-[10.25rem]'
                        }
                      >
                        <div className='flex flex-col items-center gap-3 lg:gap-1.5 xxl:gap-3'>
                          <span className='text-sm font-semibold text-highlighterText2'>{membership?.nickname}</span>
                          <div className='flex h-[1.9375rem] items-center gap-[0.3125rem] lg:mb-0.5 lg:h-[0.9375rem] xxl:mb-0 xxl:h-[1.9375rem]'>
                            <h5 className='text-[1.625rem] font-bold leading-[1.9837rem] text-highlighterText2  lg:text-lg lg:leading-[1.3613rem] xxl:text-[1.75rem] xxl:leading-[2.5rem]'>
                              {membership?.currency === CURRENCY?.USD ? '$' : ''}
                              {membership?.actualAmount}
                            </h5>
                          </div>
                          {JSON.parse(membership?.metadata?.key)?.save && (
                            <div
                              className={
                                'flex items-center justify-center rounded-[3.125rem] lg:rounded-[2.5rem] xxl:h-8 xxl:rounded-[3.125rem]'
                              }
                            >
                              <div
                                className={`flex items-center justify-center gap-[0.3125rem] rounded-[3.125rem] border px-4 py-1 text-xs font-medium leading-[1.0588rem] text-highlighterText2 lg:gap-[0.1875rem] lg:rounded-[2.5rem] lg:leading-[0.7531rem] xxl:gap-[0.3125rem] xxl:rounded-[3.125rem] xxl:text-sm xxl:leading-[1rem] ${membership?.isActiveMembership ? 'border-highlighterText2 bg-shade2' : 'border-secondaryStrokeButton'}`}
                              >
                                Save{' '}
                                <span className='text-xs font-semibold text-highlighterText2 '>
                                  ${JSON.parse(membership?.metadata?.key)?.save} / yr
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                        {membership?.subscriptionCancelAt ? (
                          <div className='flex flex-col items-center font-Manrope text-xs font-normal'>
                            <p className='text-subscriptionCancelText'>Your subscription will ends on</p>
                            <p className='text-highlighterText2'>
                              {moment(membership?.subscriptionCancelAt).format(CANCEL_MEMBERSHIP_DATE_FORMATE)}
                            </p>
                          </div>
                        ) : (
                          <Button
                            className='h-[32px] w-[134px] text-xs font-medium'
                            variant={membership?.isActiveMembership ? 'tertiary' : ''}
                            onClick={() => {
                              setClickedPlanId(membership.id);
                              membership?.isActiveMembership
                                ? setShowCancelSubscriptionDialog({
                                  ...showCancelSubscriptionDialog,
                                  [membership.id]: true,
                                })
                                : userDetail?.data?.isSubscribed
                                  ? handleMembershipUpgrade({ payload: { plan: membership?.id } })
                                  : handleMembershipCheckout({ payload: { plan: membership?.id } });
                            }}
                            disabled={
                              !membership?.isEnabled ||
                              (clickedPlanId === membership.id &&
                                (membershipCheckoutLoading || membershipUpgradeLoading))
                            }
                          >
                            {userDetail?.data?.isSubscribed
                              ? membership?.isActiveMembership
                                ? 'Cancel'
                                : 'Purchase'
                              : 'Get Started'}
                          </Button>
                        )}
                        <Modal
                          footer={null}
                          width={420}
                          closeIcon={<X color={COLORS.ICON_COLOR} />}
                          open={showCancelSubscriptionDialog[membership?.id]}
                          onCancel={() =>
                            setShowCancelSubscriptionDialog({
                              ...showCancelSubscriptionDialog,
                              [membership.id]: false,
                            })
                          }
                          centered={true}
                        >
                          <div className='flex flex-col items-center gap-6'>
                            <div className='flex flex-col items-center justify-center gap-3'>
                              <h3 className='text-center text-2xl font-bold leading-[2.125rem] text-mainText'>
                                Cancel Subscription
                              </h3>
                              <p className='text-sm leading-[1.625rem] text-bodyText'>
                                Are you sure you want to cancel your subscription?
                              </p>
                            </div>
                            <Button
                              className='w-full'
                              variant='destructive'
                              onClick={() => handleCancelMembership({ payload: '' })}
                              disabled={isCancelMembershipLoading}
                            >
                              Yes {isCancelMembershipLoading ? <Loader /> : ''}
                            </Button>
                          </div>
                        </Modal>
                        {membership?.nickname === 'Individual Plan' &&
                          membership?.interval === 'month' &&
                          membership?.trialEndDate !== null && (
                          <div className='absolute bottom-[10.825rem] flex h-7 w-40 items-center justify-center gap-[0.3125rem] rounded-[3.75rem] border border-paleBlueGray bg-white text-xs font-normal leading-[0.9075rem]  lg:h-5 lg:w-[7.125rem] lg:gap-[0.1875rem] lg:text-[0.5625rem] lg:leading-[0.6806rem]  xxl:h-7 xxl:w-40 xxl:gap-[0.3125rem] xxl:text-xs xxl:leading-[0.9075rem]'>
                              Trial ends in
                            <span className='font-semibold !text-errorTooltip'>
                              {calculateTrialDays(membership?.trialEndDate)} days
                            </span>
                          </div>
                        )}
                      </div>
                      <ul className='flex flex-col items-center justify-center space-y-5 px-4 pt-6 text-sm font-normal leading-[0.9075rem]  text-mainText'>
                        <li>{JSON.parse(membership?.metadata?.key).organizationCount}</li>
                        <li>{JSON.parse(membership?.metadata?.key).usersCount}</li>
                        <li>{JSON.parse(membership?.metadata?.key).dashboardCount}</li>
                        <li>{renderCheckOrX(JSON.parse(membership?.metadata?.key).multiDashboardCount)}</li>
                        <li>{renderCheckOrX(JSON.parse(membership?.metadata?.key).presetTemplateCount)}</li>
                        <li>{renderCheckOrX(JSON.parse(membership?.metadata?.key).dragDropBuilderCount)}</li>
                        <li>
                          {JSON.parse(membership?.metadata?.key).total_tokens !== '-' ? (
                            JSON.parse(membership?.metadata?.key).total_tokens
                          ) : (
                            <X className='m-1 h-5 w-5 stroke-bodyText' />
                          )}
                        </li>
                      </ul>
                      <div className='px-4 pb-2 pt-14 text-sm '>
                        <ul
                          className={
                            'flex flex-col items-center justify-center space-y-4 p-4 text-xs font-normal lg:space-y-3 lg:p-3 lg:text-[0.5625rem] lg:leading-[0.6806rem] xxl:space-y-4 xxl:p-4 xxl:text-xs xxl:leading-[0.9075rem]'
                          }
                        >
                          <li>{JSON.parse(membership?.metadata?.key).integrationCount}</li>
                          <li>{JSON.parse(membership?.metadata?.key).dataSourceCount}</li>
                        </ul>
                      </div>
                      <div className='px-4 pb-2 pt-16 text-sm'>
                        <ul
                          className={
                            'flex w-full flex-col items-center gap-y-4 p-4 text-xs font-normal leading-[0.9075rem] lg:gap-y-3 lg:p-3 lg:text-[0.5625rem] lg:leading-[0.6806rem] xxl:gap-y-4 xxl:p-4 xxl:text-xs xxl:leading-[0.9075rem]'
                          }
                        >
                          <li>{renderCheckOrX(JSON.parse(membership?.metadata?.key).customLogo)}</li>
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
                {/* TODO: We will integrate it with the backend */}
                <div className='custom-border mt-3.5 min-w-[13.5rem] border-l px-4 pt-6 lg:mt-2.5 lg:min-w-[9.375rem] xxl:mt-3.5 xxl:min-w-[13.5rem] '>
                  <div
                    className={
                      'relative flex h-[10.25rem] flex-col items-center justify-between border-x-paleBlueGray pb-4 lg:h-[8.75rem] xxl:h-[10.25rem]'
                    }
                  >
                    <div className='flex flex-col items-center gap-3 lg:gap-1.5 xxl:gap-3'>
                      <span className='text-sm font-semibold text-highlighterText2'> Enterprise Plan</span>
                      <div className='flex h-[1.9375rem] items-center justify-center gap-[0.3125rem] lg:mb-0.5 lg:h-[0.9375rem] lg:gap-[0.1875rem] xxl:mb-0 xxl:h-[1.9375rem] xxl:gap-[0.3125rem]'>
                        <h5 className='text-[1.625rem] font-bold leading-[1.9837rem] text-highlighterText2  lg:text-lg lg:leading-[1.3613rem] xxl:text-[1.75rem] xxl:leading-[2.5rem]'>
                          Custom
                        </h5>
                      </div>
                      <p className=' text-xs font-normal leading-[1.0625rem] lg:text-[0.5625rem] lg:leading-3 xxl:text-xs xxl:leading-[1.0625rem]'>
                        Create custom plan according to your needs
                      </p>
                    </div>
                    <Button className='h-[32px] w-[134px] text-xs font-medium' variant={'default'}>
                      <a href='mailto:sales@zendash.com' className='hover:text-white'>
                        Talk to Sales
                      </a>
                    </Button>
                  </div>
                  <ul className='flex flex-col items-center justify-center space-y-5 border-t border-paleBlueGray px-4 pt-6 text-sm  font-normal leading-[0.9075rem] text-mainText'>
                    <li>Unlimited</li>
                    <li>Unlimited</li>
                    <li>Unlimited</li>
                    <li>Unlimited</li>
                    <li>
                      <Check className='h-5 w-5' color={COLORS.SECONDARY_GREEN_COLOR} />
                    </li>
                    <li>
                      <Check className='h-5 w-5' color={COLORS.SECONDARY_GREEN_COLOR} />
                    </li>
                    <li>
                      <Check className='h-5 w-5' color={COLORS.SECONDARY_GREEN_COLOR} />
                    </li>
                  </ul>
                  <div className='px-4 pb-2 pt-14 text-sm '>
                    <ul
                      className={
                        'flex flex-col items-center justify-center space-y-4 p-4 text-xs font-normal lg:space-y-3 lg:p-3 lg:text-[0.5625rem] lg:leading-[0.6806rem] xxl:space-y-4 xxl:p-4 xxl:text-xs xxl:leading-[0.9075rem]'
                      }
                    >
                      <li>Unlimited</li>
                    </ul>
                  </div>
                  <div className='px-4 pb-2 pt-16 text-sm '>
                    <ul className='flex w-full flex-col items-center gap-y-4 p-4 text-xs font-normal leading-[0.9075rem] lg:p-3 lg:text-[0.5625rem] lg:leading-[0.6806rem] xxl:p-4 xxl:text-xs xxl:leading-[0.9075rem]'>
                      <li>
                        <Check className='h-5 w-5' color={COLORS.SECONDARY_GREEN_COLOR} />
                      </li>
                      <li>
                        <Check className='h-5 w-5' color={COLORS.SECONDARY_GREEN_COLOR} />
                      </li>
                      <li>
                        <Check className='h-5 w-5' color={COLORS.SECONDARY_GREEN_COLOR} />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
